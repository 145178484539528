import React, { memo } from 'react';
import { Paper } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import Button from '@mui/material/Button';
import { Card, CardContent } from '@material-ui/core';

function Footer(props: any) {
  const { buttons } = props;

  if (!Array.isArray(buttons)) {
    console.error('Buttons array is missing or not an array:', buttons);
    return null;
  }
  return (
    <Card style={{ position: 'fixed', bottom: 0, width: '80%' }}>
      <CardContent style={{ padding: 6 }}>
        <div className="footer-buttons">
          {buttons?.map((item: any) => {
            const { buttonStyle, onClick } = item;
            const { varient } = item;
            const { buttonName } = item;
            const { wrapperStyle } = item;
            const { disabled } = item;

            return (
              <div style={wrapperStyle}>
                <Button variant={varient} disabled={disabled} sx={buttonStyle} onClick={onClick}>
                  {buttonName}
                </Button>
              </div>
            );
          })}
        </div>
      </CardContent>
    </Card>
  );
}

export default memo(Footer);
